footer {
  background-color: #1e1e1e; color: #fff; padding: 2rem 0;
}
footer .container {
  padding: 0 2rem; max-width: 1380px;
  margin-left: auto; margin-right: auto;
}
footer img { height: 70.4px; }
footer hr {
  margin: 2rem 0; height: 1px; opacity: 0.5;
}
footer .footer {
  display: flex; flex-wrap: wrap;
  justify-content: space-between;
}
footer .footer p { padding-top: 0.5rem; font-size: 85%; }
footer .footer ul { display: flex; }
footer .footer ul li { margin-left: 1rem; }
footer .footer a, footer .footer i {
  background-color: rgb(19, 207, 240);
}
footer .footer a:hover {
  background-color: #1e1e1e;
}
footer .footer i {
  font-size: 200%; color: #fff;
  background-color: #1e1e1e;
}